import { Dynamic } from "solid-js/web";
import { classNames } from "~/lib/classNames";
import { type FileTypeIcon, useFileTypeIcon } from "~/ui/useFileTypeIcon";
import styles from "./InlineAsset.module.css";

interface Props {
  name: string;
  mimeType: string;
  inline?: boolean;
  class?: string;
}

export const InlineAsset = (props: Props) => {
  const iconTheme = useFileTypeIcon(props.mimeType as FileTypeIcon);
  return (
    <p class={classNames(styles["inline-asset"], props.inline ? styles["inline-asset--inline"] : "", props.class)}>
      <span class={styles["inline-asset__icon-container"]}>
        <Dynamic component={iconTheme.icon} class={iconTheme.theme} size="1rem" />
      </span>
      <span class={styles["inline-asset__name"]}>{props.name}</span>
    </p>
  );
};
