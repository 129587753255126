import dayjs from "dayjs";
import { For, createMemo, createSignal } from "solid-js";
import { sortObjectArray } from "~/lib/array/sortObjectArray";
import { classNames } from "~/lib/classNames";
import styles from "./CollectionAssetTable.module.css";
import { InlineAsset } from "./InlineAsset";
import { InlineAssetSummary } from "./InlineAssetSummary";

type Sort<T> = {
  field: keyof T;
  dir: SortDirection;
};

type MockData = {
  name: string;
  filename: string;
  mimeType: string;
  modifiedAt: string;
};

interface Props {
  collectionName: string;
  data: MockData[];
}

export const CollectionAssetsTable = (props: Props) => {
  const [sortOrder, setSortOrder] = createSignal<Sort<MockData>>({
    field: "modifiedAt",
    dir: "desc",
  });
  const data = createMemo(() => sortObjectArray<MockData>(sortOrder().field, props.data, sortOrder().dir));

  const onClick = (property: keyof MockData) => {
    return () => {
      if (sortOrder().field === property) {
        setSortOrder({
          field: property,
          dir: sortOrder().dir === "asc" ? "desc" : "asc",
        });
      } else {
        setSortOrder({
          field: property,
          dir: "asc",
        });
      }
    };
  };
  return (
    <div class={styles.table} data-component="collection-assets-table">
      <table class={styles.table__container}>
        <caption class="screen-reader">{`Table representing the assets associated with the ${props.collectionName} collection.`}</caption>
        <thead class={styles.table__header}>
          <tr class={styles.table__row}>
            <th class={styles["table__header-cell"]}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "name"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                aria-label="Sort by name"
                onClick={onClick("name")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Name</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            <th class={styles["table__header-cell"]}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "filename"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                aria-label="Sort by filename"
                onClick={onClick("filename")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Source</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            <th class={styles["table__header-cell"]}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "modifiedAt"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                aria-label="Sort by file size"
                onClick={onClick("modifiedAt")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Last modified</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            {/* <th class={styles.table__cell}>
              <span class={styles["table__header-label"]} />
            </th> */}
          </tr>
        </thead>
        <tbody class={styles.table__body} aria-busy={false}>
          <For each={data()}>
            {(item) => {
              return (
                <tr class={styles.table__row} tabIndex={0}>
                  <td class={styles.table__cell}>
                    <InlineAssetSummary summary={item.name} />
                  </td>
                  <td class={styles.table__cell}>
                    <InlineAsset name={item.filename} mimeType={item.mimeType} />
                  </td>
                  <td class={styles.table__cell}>
                    <span class={styles["table__cell-text"]}>{dayjs(item.modifiedAt).format("LL")}</span>
                  </td>
                  {/* <td class={styles.table__cell} /> */}
                </tr>
              );
            }}
          </For>
        </tbody>
      </table>
    </div>
  );
};
