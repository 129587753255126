import type { projects } from "@repo/client";
import { TbFolderFilled } from "solid-icons/tb";
import { type Component, Show, Suspense, createSignal } from "solid-js";
import { CollectionAssetsTable } from "~/components/SideBar/CollectionAssetTable";
import { LargeFileUpload } from "~/components/SideBar/LargeFileUpload";
import { SectionHeader } from "~/components/SideBar/SectionHeader";
import { ContentContainer } from "~/components/_containers/ContentContainer";
import { ChatFileUpload } from "~/domains/chat/prompt/components/ChatFileUpload";
import type { CollectionSnapshot } from "~/domains/collections/collections.types";
import { useUIState } from "~/ui/UIState";

export const CollectionsAssetsScreen: Component<{
  collection: CollectionSnapshot | undefined;
  assets: projects.AssetSnapshot[];
  refreshAssets: () => void;
}> = (props) => {
  const state = useUIState();
  const [, setTab] = state.mainContentTab;

  const [assetUploadModal, setAssetUploadModal] = createSignal(false);
  return (
    <Suspense>
      <ChatFileUpload
        context="project"
        open={assetUploadModal()}
        setOpen={setAssetUploadModal}
        onUploaded={() => {
          props.refreshAssets();
          setTab("Assets");
        }}
      />
      <ContentContainer>
        <LargeFileUpload showUploadModal={() => setAssetUploadModal(true)} />
      </ContentContainer>
      <Show when={props.assets.length}>
        <ContentContainer>
          <SectionHeader title="All assets in this collection" icon={TbFolderFilled} />
          <CollectionAssetsTable
            collectionName={props.collection?.label || ""}
            data={props.assets.map((a) => ({
              id: a.id,
              name: a.displayName,
              modifiedAt: a.modifiedAt,
              mimeType: a.originalFilename.split(".").pop()?.toLowerCase() || "",
              filename: a.originalFilename,
            }))}
          />
        </ContentContainer>
      </Show>
    </Suspense>
  );
};
