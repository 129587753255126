import { TbMessage } from "solid-icons/tb";
import styles from "./InlineChat.module.css";

interface Props {
  label: string;
}

export const InlineChat = (props: Props) => {
  return (
    <p class={styles.inlineChat}>
      <span class={styles.inlineChatIconContainer}>
        <TbMessage class={styles.inlineChatCtaIcon} size="1.25rem" />
      </span>
      <span class={styles.inlineChatLabel}>{props.label}</span>
    </p>
  );
};
