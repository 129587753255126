import type { ParentProps } from "solid-js";
import { classNames } from "~/lib/classNames";
import styles from "./ContentTableContainer.module.css";

interface Props extends ParentProps {
  class?: string;
}

export const ContentTableContainer = (props: Props) => (
  <section {...props} class={classNames(styles.contentTableContainer, props.class)}>
    {props.children}
  </section>
);
