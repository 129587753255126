import type { RouteSectionProps } from "@solidjs/router";
import { type Component, Match, Show, Suspense, Switch, createMemo, onMount } from "solid-js";
import { SkipToContent } from "~/components/CTA/SkipToContent";
import { SEOHeaders } from "~/components/SEOHeaders";
import { AlternativeSideBar } from "~/components/SideBar/AlternativeSideBar";
import { CollectionsTitleBar } from "~/components/SideBar/CollectionsTitleBar";
import { CollectionsTop } from "~/components/SideBar/CollectionsTop";
import { MainContentTabs } from "~/components/SideBar/MainContentTabs";
import { CollectionsModal } from "~/components/_containers/CollectionsModal";
import { ContentContainer } from "~/components/_containers/ContentContainer";
import { FloatingPromptBarContainer } from "~/components/_containers/FloatingPromptBarContainer";
import { RightDrawer } from "~/components/_containers/RightDrawer";
import { TwoColumnLayout } from "~/components/_layouts/TwoColumnLayout";
import { Bug } from "~/components/dev";
import { PersistentPrompt } from "~/domains/chat/prompt/Prompt";
import { PromptContextProvider } from "~/domains/chat/prompt/PromptContext";
import { useIsIdentityConnecting } from "~/domains/identity/hooks";
import type { CampaingPageDataPrompt } from "~/domains/marketing/useUseCasesData";
import { NotFoundScreen } from "~/screens/NotFoundScreen";
import { CollectionsAssetsScreen } from "~/screens/subscreen/CollectionsAssetsScreen";
import { CollectionsChatsScreen } from "~/screens/subscreen/CollectionsChatsScreen";
import { CollectionsGettingStartedScreen } from "~/screens/subscreen/CollectionsGettingStartedScreen";
import { LoadingScreen } from "~/screens/subscreen/LoadingScreen";
import { useUIState } from "~/ui/UIState";
import { useWire } from "~/wire";

type Props = { prompt?: CampaingPageDataPrompt } & RouteSectionProps<unknown>;

export const CollectionScreen: Component<Props> = (props) => {
  const wire = useWire();
  const state = useUIState();
  const [, setTab] = state.mainContentTab;
  const isConnecting = useIsIdentityConnecting();
  const collections = wire.services.collections;
  const threads = wire.services.threads;
  const urlId = () => props.params.collection_id;

  const active = () => collections.getCollection(urlId() || "");

  const [gettingStartedScreenManuallyHidden] = state.gettingStartedScreenManuallyHidden;

  const [tree, { refetch: refetchTree }] = collections.globalResourceTree;
  const [recentThreads] = threads.threadsListResource;

  const [collectionThreads, { refetch: refetchCollectionThreads }] = threads.resourceCollectionThreads(() =>
    isConnecting() ? undefined : urlId(),
  );

  const [collectionAssets, { refetch: refetchCollectionAssets }] = collections.resourceCollectionAssets(() =>
    isConnecting() ? undefined : urlId(),
  );

  const is404 = () => !tree.loading && active() === undefined;

  // TODO: @andi better way to manage thread state
  onMount(() => {
    wire.services.threads.send(wire.services.threads.eventFactory.newResetEvent());
  });

  const isUserGettingStarted = createMemo(() => {
    // If any of the root collections have at least one collection inside, user is not getting started
    for (const col of tree()?.data || []) {
      if ((collections.getChildrenIds(col.collection.id)?.length || 0) > 0) {
        return false;
      }
    }

    // If user has any threads, they're not just getting started
    if ((recentThreads()?.data.result.entities.length || 0) > 1) {
      return false;
    }

    return true;
  });

  return (
    <PromptContextProvider
      initialPrompt={props.prompt?.prompt.name ? { content: props.prompt.prompt.name, highlight: true } : undefined}
      transformationID={props.prompt?.prompt.id}
      autoFocusInput
      hideBackdrop
      activeCollection={active}
      inBackground
      positioning="sticky bottom-0"
      overrideOnFilesUploaded={() => {
        refetchCollectionAssets();
        setTab("Assets");
      }}
    >
      <SEOHeaders title={props.prompt?.prompt.name} description={props.prompt?.prompt.summary} />
      <Show when={!wire.metadata.isDefault}>
        <Suspense>
          <Bug />
          <CollectionsModal accessibleLabel="Permissions menu." />
          <RightDrawer accessibleLabel="Permissions menu." />
          <SkipToContent />
          <TwoColumnLayout
            sidebar={<AlternativeSideBar collectionId={active()?.id} />}
            content={
              <>
                <CollectionsTop showGettingStarted={isUserGettingStarted() && gettingStartedScreenManuallyHidden()} />

                <Switch>
                  <Match when={isConnecting() || tree.loading || recentThreads.loading}>
                    <LoadingScreen />
                  </Match>
                  <Match when={is404()}>
                    <NotFoundScreen />
                  </Match>

                  <Match when={!gettingStartedScreenManuallyHidden() && isUserGettingStarted()}>
                    <ContentContainer root>
                      <CollectionsGettingStartedScreen
                        assets={collectionAssets()?.data.result.entities.map((a) => a.data) || []}
                      />
                    </ContentContainer>
                  </Match>

                  <Match when={true}>
                    <CollectionsTitleBar
                      collectionId={active()?.id || ""}
                      label={active()?.label || ""}
                      description={active()?.description}
                      onCollectionCreated={refetchTree}
                      breadcrumbs={collections.getBreadcrumbs(active()?.id || "")}
                    />
                    <ContentContainer root>
                      <MainContentTabs
                        Chats={
                          <CollectionsChatsScreen
                            collection={active()}
                            threads={collectionThreads()?.data.result.entities.map((t) => t.data) || []}
                          />
                        }
                        Assets={
                          <CollectionsAssetsScreen
                            collection={active()}
                            assets={collectionAssets()?.data.result.entities.map((t) => t.data) || []}
                            refreshAssets={refetchCollectionAssets}
                          />
                        }
                        // KitchenSink={<KitchenSinkScreen />}
                      />
                      <FloatingPromptBarContainer>
                        <PersistentPrompt />
                      </FloatingPromptBarContainer>
                    </ContentContainer>
                  </Match>
                </Switch>
              </>
            }
          />
        </Suspense>
      </Show>
    </PromptContextProvider>
  );
};
