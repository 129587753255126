import { Show } from "solid-js/web";
import { StIcon, type StIconComponent } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./SectionHeader.module.css";

interface Props {
  title: string;
  icon?: StIconComponent;
  expandLabel?: string;
}
export const SectionHeader = (props: Props) => {
  return (
    <p class={styles.sectionHeader}>
      <Show when={props.icon}>
        <span class={styles.sectionHeaderIconContainer}>
          {/* biome-ignore lint/style/noNonNullAssertion: Not correctly identifying <Show> conditional */}
          <StIcon icon={props.icon!} class={styles.sectionHeaderIcon} size="1.5rem" />
        </span>
      </Show>
      <span class={classNames(styles.sectionHeaderTitle)}>{props.title}</span>
      <Show when={props.expandLabel}>
        <span class={styles.sectionHeaderButtonContainer}>
          <button
            type="button"
            class={styles.sectionHeaderButton}
            aria-label={`Toggle ${props.title} section.`}
            tabIndex={0}
          >
            <span class={styles.sectionHeaderButtonLabel}>{props.expandLabel}</span>
          </button>
          <button
            type="button"
            class={styles.sectionHeaderButtonMobile}
            aria-label={`Toggle ${props.title} section.`}
            tabIndex={0}
          >
            <span class={styles.sectionHeaderButtonLabel}>+</span>
            <span class="screen-reader">{props.expandLabel}</span>
          </button>
        </span>
      </Show>
    </p>
  );
};
