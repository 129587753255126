import type { projects } from "@repo/client";
import { BsPersonFillLock } from "solid-icons/bs";
import { TbPlus } from "solid-icons/tb";
import { type Component, For, Match, Suspense, Switch } from "solid-js";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { SecondaryCTA } from "../CTA/SecondaryCTA";
import { StIcon } from "../icons";
import styles from "./GettingStarted.module.css";
import { InlineAsset } from "./InlineAsset";
import { LargeFileUpload } from "./LargeFileUpload";

export const GettingStartedUpload: Component<{ assets: projects.AssetSnapshot[] }> = (props) => {
  const { setShowUploadModal } = usePromptContext();
  const hasAssets = () => !!props.assets.length;

  const label = () =>
    hasAssets() ? `You have ${props.assets.length} assets in your` : "These will be first saved in the selected";
  return (
    <Suspense>
      <div class={styles["getting-started"]}>
        <p class={styles["getting-started__step"]}>
          <span class={styles["getting-started__step-text"]}>1.</span>
        </p>
        <p class={styles["getting-started__heading"]}>
          <span class={styles["getting-started__heading-title"]}>Add assets.</span>
          <span class={styles["getting-started__heading-subtitle"]}>
            <span class={styles["getting-started__heading-text"]}>{label()}</span>
            <span class={styles["getting-started__accordion"]}>
              <StIcon class={styles["getting-started__accordion-icon"]} icon={BsPersonFillLock} size="1.5rem" />
              <span class={styles["getting-started__accordion-text"]}>Personal Collection</span>
            </span>
          </span>
        </p>
        <div class={styles["getting-started__actions"]}>
          <Switch>
            <Match when={hasAssets()}>
              <div>
                <For each={props.assets}>
                  {(asset) => (
                    <InlineAsset
                      class={styles["getting-started__asset"]}
                      inline
                      name={asset.displayName || asset.originalFilename}
                      mimeType={asset.originalFilename.split(".").pop() || ""}
                    />
                  )}
                </For>
                <SecondaryCTA
                  onClick={() => setShowUploadModal(true)}
                  label="Add more"
                  accessiblePrefix=""
                  accessibleSuffix=" assets."
                  icon={TbPlus}
                  data-test-id="add-more-assets"
                />
              </div>
            </Match>
            <Match when={true}>
              <LargeFileUpload hideLabel solid showUploadModal={() => setShowUploadModal(true)} />
            </Match>
          </Switch>
        </div>
      </div>
    </Suspense>
  );
};
