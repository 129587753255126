import styles from "./InlineAssetSummary.module.css";

interface Props {
  summary: string;
}

export const InlineAssetSummary = (props: Props) => {
  return (
    <p class={styles.inlineAssetSummary}>
      {/* <StIcon icon={AiOutlineFile} class={styles.inlineAssetSummaryIcon} size="1.25rem" /> */}
      <span class={styles.inlineAssetSummaryText}>{props.summary}</span>
    </p>
  );
};
