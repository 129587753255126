import { For, type ParentProps, children } from "solid-js";
import { classNames } from "~/lib/classNames";
import { capitalize } from "~/lib/string/capitalize";
import styles from "./FlexibleGrid.module.css";

interface Props extends ParentProps {
  class?: string;
  columns?: "one" | "two" | "three" | "four";
  stretch?: boolean;
  gap?: "large";
  margin?: "large" | "xl";
}

export const FlexibleGrid = (props: Props) => {
  const resolved = children(() => props.children);
  return (
    <div
      class={classNames(
        styles.flexibleGrid,
        props.columns ? styles[`flexibleGrid${capitalize(props.columns)}`] : "",
        props.stretch ? styles.flexibleGridStretch : "",
        props.gap ? styles[`flexibleGrid${capitalize(props.gap)}Gap`] : "",
        props.margin ? styles[`flexibleGrid${capitalize(props.margin)}Margin`] : "",
        props.class,
      )}
    >
      <div class={styles.flexibleGridContainer}>
        <For each={resolved.toArray()}>{(child) => child && <div class={styles.flexibleGridItem}>{child}</div>}</For>
      </div>
    </div>
  );
};
